<template>
<v-form v-model="valid" @submit.prevent='dologin' >
<v-card tile outlined class='elevation-20' >
  <v-card-title class='subtitle-1 overline orange white--text' >
    <v-icon dark class='mr-3' >mdi-lock</v-icon>
    Please login
  </v-card-title>
  <v-divider></v-divider>
  <v-card-text class='pt-10' >
    <request-status :request='request.login' />
    <v-text-field filled required v-model="email" type='email' label='email' />
    <v-text-field filled required v-model='password' label='password' type='password' />
  </v-card-text>
  <!-- R: {{ redirectPath }} | {{isLoggedIn}} -->
  <v-card-actions >
    <v-spacer ></v-spacer>
    <v-btn color='primary' :disabled='!valid' :loading='request.login.loading' type='submit' >Login</v-btn>
  </v-card-actions>
  <v-divider></v-divider>
  <v-footer >
    <v-btn @click='$emit("forgot", email)' x-small text >
    Forgot password
    </v-btn>
  </v-footer>
</v-card>
</v-form>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  name: 'LoginView',
  data () {
    return {
      valid: false,
      email: null,
      password: null,
      request: {
        login: { resource: 'auth.login' }

      }
    }
  },
  computed: {
    ...mapGetters(
        'auth', [
        'redirectPath',
        'isLoggedIn'
      ]
    ),
    url () {
      return process.env.VUE_APP_AUTHENTICATIONGURU_API_ROOT
    },
    requestStatus () {
      return this.request.login.status
    }
  },
  watch: {
    requestStatus () {
      if (this.requestStatus === 200) {
        let path = this.redirectPath
        let token = this.getObjectPath(this.request.login, 'result.access_token')
        this.$store.dispatch('auth/logUserIn', token)
        this.$router.push({path})
      }
    }
  },
  methods: {
    dologin () {
      this.request.login.data = {
        username: this.email,
        password: this.password,
        client_id: "94A049E4-5D1F-4D78-B090-0309C68BA2DE",
        client_secret: "C455E955-767F-418C-B69B-A0C7D10D44DD"
      }
      this.$request('save', this.request.login)
    }
  }
}
</script>
