<template>
<v-row >
  <v-col class='justify-center offset-md-3 col-md-6' >
    <v-window v-model='window' vertical >
      <v-window-item >
        <login @forgot='window=WINDOWS.RESET' />
      </v-window-item>
      <v-window-item >
        <reset-password @back='window=WINDOWS.LOGIN' />
      </v-window-item>
    </v-window>
  </v-col>
</v-row>
</template>
<script>
import ResetPassword from './Reset.vue'
import Login from './Login.vue'

const WINDOWS = {
  LOGIN: 0,
  RESET: 1
}

export default {
  name: 'LoginView',
  components: {ResetPassword, Login},
  data () {
    return {
      window: 0,
      WINDOWS: WINDOWS
    }
  }
}
</script>
