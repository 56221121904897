<template>
<v-card>
  <v-card-title class='subtitle-1 overline' >
    Reset password
  </v-card-title>
  <v-card-text>
    <v-text-field label='Your new password' />
    <v-text-field label='Retype password' />
  </v-card-text>
  <v-card-actions >
    <v-btn @click='$emit("back")' text >Back</v-btn>
    <v-spacer ></v-spacer>
    <v-btn color='primary' >Reset Password</v-btn>
  </v-card-actions>
</v-card>
</template>
<script>
export default {
  name: 'ResetPassword'
}
</script>